import React from "react";
import ReactDOM from "react-dom";
import { Provider, themes, Flex } from "@stardust-ui/react";
import { VirtualList } from "./VirtualList";

const App = () => (
  <Flex>
    <div style={{ border: "1px solid" }}>
      <VirtualList />
    </div>
    <div style={{ border: "1px solid" }}>
      <VirtualList isWithDelay={true} />
    </div>
  </Flex>
);

ReactDOM.render(
  <Provider theme={themes.teams}>
    <App />
  </Provider>,

  document.getElementById("root")
);
