import React from "react";

export const Delay = ({ placeholder, delayMs, children }) => {
  const [inDelay, setInDelay] = React.useState(true);

  React.useEffect(() => {
    const _timerId = setTimeout(() => {
      setInDelay(false);
    }, delayMs);
    return () => clearTimeout(_timerId);
  }, []);

  if (!inDelay) {
    return children;
  }

  if (placeholder) {
    return placeholder;
  }

  return null;
};
