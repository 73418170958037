import React from "react";
import { Flex } from "@stardust-ui/react";
import { Delay } from "./Delay";
import { StartMedia } from "./components/StartMedia";
import { Content } from "./components/Content";
import { EndMedia } from "./components/EndMedia";

export function ListItem({ isWithDelay }) {
  if (isWithDelay) {
    return (
      <Flex space="between" style={{ width: "100%" }}>
        <Delay placeholder={<SvgAvatarPlaceholder />} delayMs={1}>
          <StartMedia />
        </Delay>
        <Delay placeholder={<SvgPlaceholder width={120} />} delayMs={50}>
          <Content />
        </Delay>
        <Delay placeholder={<SvgPlaceholder width={50} />} delayMs={50}>
          <EndMedia />
        </Delay>
      </Flex>
    );
  } else {
    return (
      <Flex space="between" style={{ width: "100%" }}>
        <StartMedia />
        <Content />
        <EndMedia />
      </Flex>
    );
  }
}

export const SvgAvatarPlaceholder = ({ size = 32 }) => (
  <svg fill="#EDEBE9" height={size} width={size}>
    <circle cx={size / 2} cy="50%" r={size / 2} />
  </svg>
);

function SvgPlaceholder({ height = 7, width = 100, rx = 3, ry = 3 }) {
  return (
    <svg height={height} width={width} fill="#EDEBE9">
      <rect x="0" y="0" width={width} height={height} rx={rx} ry={ry} />
    </svg>
  );
}
