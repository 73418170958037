import React from "react";
import { FixedSizeList as List } from "react-window";

import "./styles.css";
import { ListItem } from "./ListItem";

export const VirtualList = ({ isWithDelay }) => {
  const Row = ({ index, style }) => (
    <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
      <ListItem isWithDelay={isWithDelay} />
    </div>
  );

  return (
    <List
      className="List"
      height={500}
      itemCount={1000}
      itemSize={48}
      width={300}
    >
      {Row}
    </List>
  );
};
